// @flow

import styled, { css } from "styled-components";
// import colorShifter from "components/utils/colorShifter";
import {
  colors,
  fontSize,
  spacing,
  borderRadius,
} from "components/styles/variables";

const ButtonStyle = css`
  font-size: ${fontSize.h5}px;
  display: inline-block;
  text-decoration: none;
  border: 2px solid transparent;
  padding: 12px 10px;
  min-width: 140px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  span {
    position: relative;
    z-index: 1;
  }

  /* Primary */
  ${props => props.primary && PrimaryStyle};

  /* Primary Ghost */
  ${props =>
    props.primaryGhost &&
    css`
      border-color: ${colors.primary};
      color: ${colors.primary};
      box-shadow: none;
    `};

  /* Secondary */
  ${props =>
    props.secondary &&
    css`
      background: ${colors.yellow};
      box-shadow: 0 4px 8px rgba(254, 203, 64, 0.42);
    `};

  /* Text */
  ${props =>
    props.text &&
    css`
      padding: 0;
      color: ${colors.yellow};
      min-width: auto;
    `};
`;

// Primary
const PrimaryStyle = css`
  position: relative;
  color: ${colors.primary};
  transition: color 500ms ease;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: ${colors.primary};
    z-index: 0;
    transition: width 500ms ease;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3px;
    background-color: ${colors.primary};
    z-index: 0;
    transition: width 500ms ease;
  }

  &:disabled {
    &:after,
    &:before {
      display: none;
    }
  }

  &:hover,
  &:focus {
    color: white;

    &:before {
      width: 100%;
    }

    &:after {
      width: 100%;
    }

    &:disabled {
      color: ${colors.primary};
    }
  }
`;

export const LinkButton = styled.a`
  ${ButtonStyle}
`;

export const Button = styled.button`
  ${ButtonStyle}
`;

export const ToggleButton = styled.button`
  background: ${props => (props.active ? colors.primary : colors.gray)};
  position: relative;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 ${spacing.medium}px;
  border-color: ${props => (props.active ? colors.primary : colors.gray)};
  transition: background 250ms ease;

  &:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 100%;
    top: 4px;
    left: ${props => (props.active ? "64px" : "4px")};
    transition: left 250ms ease;
  }
`;

export const LargeButton = styled.button`
  ${ButtonStyle}
  padding: ${spacing.medium}px ${spacing.xlarge}px;
  background: ${colors.primary};
  color: ${colors.white};
  font-size: ${fontSize.h4}px;
  cursor: pointer;
  border-radius: ${borderRadius.base}px;
  transition: background 500ms ease;

  &:hover, &:focus {
    background: ${colors.redDark};
  }
`;
