// @flow
import styled from "styled-components";
import { mediaQueries, spacing } from "components/styles/variables";

export const HomePage = styled.div`
  min-height: 600px;

  position: relative;
  overflow: hidden;
  padding-top: 160px;

  ${mediaQueries.smMin`
    height: 100vh;
    padding-top: 0;
  `}
`;

export const BgCard = styled.div`
  position: absolute;
  top: 0;
  width: 200px;
  height: 250px;
  background-image: linear-gradient(
    -180deg,
    rgba(250, 228, 228, 0.5) 0%,
    rgba(254, 247, 247, 0) 100%
  );
`;

export const Previews = styled.div`
  position: relative;
  margin-top: ${spacing.base}px;

  ${mediaQueries.smMin`
    height: 100vh;
  `}
`;

export const Preview = styled.div`
  padding: 0 10px;
  width: 50%;
  display: none;
  vertical-align: top;
  background-size: 100%;

  ${mediaQueries.smMin`
    display: block;
    width: 300px;
    height: 380px;
    position: absolute;
  `}
`;
