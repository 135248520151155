// @flow
import React from "react";
import PublicPage from "../templates/PublicPage";
import { BgCard, HomePage, Previews, Preview } from "./home.styles";
import { Col, Row, Container } from "components/styles/layout";
import { Lead, H1 } from "components/styles/type";
import { LinkButton } from "components/styles/buttons";
import { colors, spacing } from "components/styles/variables";

import image1 from "assets/images/image1.png";
import image2 from "assets/images/image2.png";
import image3 from "assets/images/image3.png";
import image4 from "assets/images/image4.png";
import image5 from "assets/images/image5.png";

const Home = () => {
  return (
    <PublicPage>
      <HomePage>
        <BgCards />
        <Container>
          <Row middle>
            <Col sm7>
              <H1 style={{ maxWidth: 600, color: colors.primary }}>
                An Identity & Development Studio
              </H1>
              <Lead style={{ maxWidth: 400, marginBottom: spacing.large }}>
                Strategy, Design, and Development. We help your business
                prototype and launch successful brands and websites.
              </Lead>
              <LinkButton primary href="mailto:chris@hotfix.studio">
                <span>Lets Talk</span>
              </LinkButton>
            </Col>
            <Col sm5>
              <HomePreviews />
            </Col>
          </Row>
        </Container>
      </HomePage>
    </PublicPage>
  );
};

const BgCards = () => {
  const cards = [
    { x: 0.05, y: 0.15 },
    { x: -0.1, y: 0.55 },
    { x: 0.35, y: -0.05 },
    { x: 0.25, y: 0.65 },
    { x: 0.5, y: 0.85 },
    { x: 0.6, y: 0.35 },
    { x: 0.85, y: 0.15 },
    { x: 0.95, y: 0.75 },
  ];
  return (
    <div>
      {cards.map((c, index) => (
        <BgCard
          key={index}
          style={{ top: c.y * 100 + "%", left: c.x * 100 + "%" }}
        />
      ))}
    </div>
  );
};

const HomePreviews = () => {
  const images = [
    { src: image1, x: -0.1, y: 0.3 },
    { src: image2, x: 0.6, y: 0.05 },
    { src: image3, x: -0.5, y: 0.8 },
    { src: image4, x: 0.5, y: 0.7 },
    { src: image5, x: -0.3, y: -0.1 },
  ];

  return (
    <Previews>
      {images.map((imgObj, i) => (
        <Preview
          key={i}
          style={{
            top: imgObj.y * 100 + "%",
            left: imgObj.x * 100 + "%",
            backgroundImage: `url(${imgObj.src})`,
          }}
        />
      ))}
    </Previews>
  );
};

export default Home;
